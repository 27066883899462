<script setup lang="ts">
import { OrganizationUserRole } from "docuchatcommontypes";
import { DcPlan, DcPlanCycle } from "~/types/plan";
import { DcEvent } from "~/types/posthog";

withDefaults(defineProps<{ preventClose?: boolean; }>(), {
  preventClose: true,
});

const { $sentry, $posthog } = useNuxtApp();

const toast = useToast();

const { user, canUseApp, isPlanExpired } = storeToRefs(useUserStore());
const { updatePlan, getBillingPortalUrl, fetchUserData } = useUserStore();
const { signOut } = useAuthentication();
const { isOnPostcheckoutPage } = useNavigation();

// We show the modal if the user cannot use the app and is not on the post checkout page.
const open = computed(() => !isOnPostcheckoutPage() && !canUseApp.value && user.value?.organizationUserRole === OrganizationUserRole.SuperAdmin);

const { plans } = getPricingData(handleSubscribeClick, isPlanExpired.value);
const billYearly = ref(false);

const header = computed(() => {
  if (isPlanExpired.value) {
    return {
      title: "Trial/Plan Expired",
      description: "Your trial/subscription has expired. Please subscribe to a plan to continue using DocuChat.",
    };
  }

  return {
    title: "Get Started with DocuChat",
    description: "Try for free with our 14-day trial. No credit card required.",
  };
});

async function handleSubscribeClick(plan: DcPlan) {
  if (plan === DcPlan.Enterprise) {
    const subject = "DocuChat Enterprise Plan";
    const mailTo = "mailto:hi@docuchat.io";
    const mailToWithSubject = `${mailTo}?subject=${encodeURIComponent(subject)}`;
    window.open(mailToWithSubject, "_blank");
    return;
  }

  sendProcesingNoification();

  try {
    // If this is the first time the user is choosing a plan, we set the plan to trial.
    const processedPlan = !user.value?.isPlanChosen ? DcPlan.Trial : plan;
    const redirectUrl = await updatePlan(processedPlan, billYearly.value ? DcPlanCycle.Annual : DcPlanCycle.Monthly);

    // Subscribe
    if (redirectUrl) {
      $posthog.capture(DcEvent.UserSubscribeIntent, { plan, billYearly: billYearly.value });
      await navigateTo(redirectUrl, { external: true });
    }
    // Trial
    else if (processedPlan === DcPlan.Trial) {
      await fetchUserData();
      toast.add({
        title: "Enjoy your free trial!",
        description: "You can now create your first chatbot. Click 'Create Chatbot' to start.",
        icon: "i-carbon-lightning",
      });
      throwConfetti();
      $posthog.capture(DcEvent.UserStartedTrial, { plan });
    }
    else {
      await fetchUserData();
      $sentry.captureError("No redirect URL was received after subscription request, and it was not a trial plan.", {
        extra: {
          plan,
          processedPlan,
          billYearly: billYearly.value,
        },
      });
    }
  }
  catch (error: any) {
    toast.add({
      title: "We've encountered an error",
      description: error.message || "Can you please try again?",
      icon: "i-carbon-warning",
      color: "red",
    });
  }
}

async function handleOpenBillingPortal() {
  sendProcesingNoification();

  try {
    const url = await getBillingPortalUrl();
    await navigateTo(url, { external: true });
  }
  catch (error: any) {
    toast.add({
      title: "We've encountered an error",
      description: error.message || "Can you please try again?",
      icon: "i-carbon-warning",
      color: "red",
    });
  }
}

function sendProcesingNoification() {
  toast.add({
    title: "Processing...",
    description: "Please wait while we process your request.",
    icon: "i-carbon-time",
  });
}

onMounted(async () => {
  // If the user is coming from the landing page with a subscribe query, we open the modal with the plan selected.
  const { subscribe, billYearly: billYearlyRequested } = useRoute().query;
  if (billYearlyRequested)
    billYearly.value = billYearlyRequested === "true";

  if (subscribe)
    await handleSubscribeClick(subscribe as DcPlan);
});
</script>

<template>
  <UModal
    v-model="open"
    :prevent-close="preventClose"
    :ui="{
      width: 'sm:max-w-6xl',
    }"
  >
    <UCard>
      <div class="my-4">
        <h1 class="text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          {{ header.title }}
        </h1>
        <p class="mt-2 text-center text-base text-gray-500 dark:text-gray-400">
          {{ header.description }}
        </p>
      </div>

      <TheModalSubscribePricingTable
        v-model="billYearly"
        :plans="plans"
      />

      <!-- Footer Actions -->
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <UButton
            variant="ghost"
            size="xs"
            @click.prevent="signOut"
          >
            Sign Out
          </UButton>
          <UButton
            v-if="isPlanExpired"
            variant="soft"
            size="xs"
            @click.prevent="handleOpenBillingPortal"
          >
            Billing Portal
          </UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
