import { DcPlan } from "../types/plan";

export function getPricingData(onSubscribeClicked?: (plan: DcPlan) => Promise<void>, isPlanExpired?: boolean) {
  return {
    icon: "i-carbon-money",
    title: "Pricing",
    description: "Try for free with our 14-day trial. No credit card required.",
    plans: [
      {
        id: DcPlan.Essential,
        title: "Essential",
        description: "All the basics to get you started.",
        price: "$17",
        discountedPrice: "",
        yearlyPrice: "$14",
        discountedYearlyPrice: "",
        cycle: "/month",
        highlight: false,
        button: {
          label: isPlanExpired ? "Subscribe" : "Start Free Trial",
          click: async () => {
            if (!onSubscribeClicked)
              throw createError("onSubscribeClicked is not defined in useLandingCopy");

            await onSubscribeClicked(DcPlan.Essential);
          },
        },
        features: [
          "3 chatbots",
          "1,000 question credits per month (means 1,000 GPT-3.5 questions)",
          "10,000 pages of source documents per month",
          "Share privately with a link or embed on your site",
          "Standard support via chat and email",
        ],
      },
      {
        id: DcPlan.Pro,
        title: "Pro",
        description: "For growing teams and businesses.",
        price: "$37",
        discountedPrice: "",
        yearlyPrice: "$31",
        discountedYearlyPrice: "",
        cycle: "/month",
        highlight: true,
        badge: "Most Popular",
        button: {
          label: isPlanExpired ? "Subscribe" : "Start Free Trial",
          click: async () => {
            if (!onSubscribeClicked)
              throw createError("onSubscribeClicked is not defined in useLandingCopy");

            onSubscribeClicked(DcPlan.Pro);
          },
        },
        features: [
          "Unlimited chatbots",
          "4,500 question credits per month (4,500 GPT-3.5 or 375 Mistral Large or 300 GPT-4 questions)",
          "100,000 pages of source documents per month",
          "Share privately with a link or embed on your site",
          "Branding and white label options",
          "Team features and role-based access control",
          "Priority support via chat and email",
        ],
      },
      {
        id: DcPlan.Enterprise,
        title: "Enterprise",
        description: "For large teams and enterprises.",
        price: "Let's talk",
        discountedPrice: "",
        yearlyPrice: "",
        discountedYearlyPrice: "",
        cycle: "",
        highlight: false,
        button: {
          label: "Contact Us",
          click: () => {
            if (!onSubscribeClicked)
              throw createError("onSubscribeClicked is not defined in useLandingCopy");

            onSubscribeClicked(DcPlan.Enterprise);
          },
        },
        features: [
          "All Pro features",
          "As many questions or pages as you need",
          "Top priority support via chat and email",
        ],
      },
    ],
  };
}
