<script lang="ts" setup>
import { DcPlan, type DcPricingPlan } from "~/types/plan";

defineProps<{
  plans: DcPricingPlan[];
}>();

const annualPricing = defineModel<boolean>({ default: false });

function getEnterprisePrice(plan: DcPricingPlan) {
  if (plan.id === DcPlan.Enterprise) {
    return {
      price: plan.price,
      discountedPrice: undefined,
      cycle: undefined,
    };
  }
  else {
    return {
      price: !annualPricing.value ? plan.price : plan.yearlyPrice,
      discountedPrice: !annualPricing.value ? plan.discountedPrice : plan.discountedYearlyPrice,
      cycle: !annualPricing.value ? plan.cycle : `${plan.cycle} (billed yearly)`,
    };
  }
}
</script>

<template>
  <div>
    <div class="mb-10 flex justify-center">
      <UPricingToggle
        v-model="annualPricing"
        class="max-w-sm"
        left="Monthly"
        right="Yearly (2 Months Free)"
        :ui="{
          marker: 'bg-primary-600 dark:bg-white',
        }"
      />
    </div>
    <UPricingGrid>
      <UPricingCard
        v-for="plan in plans"
        :key="plan.title"
        :title="plan.title"
        :description="plan.description"
        :price="getEnterprisePrice(plan).price"
        :discount="getEnterprisePrice(plan).discountedPrice"
        :cycle="getEnterprisePrice(plan).cycle"
        :badge="plan.badge ? { label: plan.badge } : undefined"
        :highlight="plan.highlight"
        :features="plan.features"
        :button="plan.button"
        align="bottom"
        :ui="{
          strategy: 'override',
          features: {
            item: {
              label: 'text-gray-500 dark:text-gray-400',
            },
          },
        } as any"
      />
    </UPricingGrid>
    <p class="mt-8 text-center text-sm">
      All plans include OpenAI or other AI providers' API costs. You don't need to pay for them separately.
    </p>
  </div>
</template>
